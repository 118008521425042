import React from 'react';
import './home.css';
import { Link } from 'react-router-dom';
import MyButton from '../../component/Button/button';
import { Row, Col, Card, Image } from 'antd'; 
import { useNavigate  } from 'react-router-dom';
import Footer from '../../component/Footer/footer';

function Home() {
    const { Meta } = Card;
    const product_image =  {
        1705:{
            name: "1705",
            category:"Adult",
            price: "RM 268",
            size:"50-18-137",
            colour:[
            { image: "20231031_113436.jpg", file:'1705', colour: "Size" },
            { image: "1705_Black.jpg", file:'1705', colour: "Black" },
            { image: "1705_Blue.jpg", file:'1705', colour: "Blue" },
            { image: "1705_Brown.jpg", file:'1705', colour: "Brown" },
            { image: "1705_Green.jpg", file:'1705', colour: "Green" },
            { image: "1705_Purple.jpg", file:'1705', colour: "Purple" },
            { image: "1705_Red.jpg", file:'1705', colour: "Red" },
            { image: "1705_White.jpg", file:'1705', colour: "WhiteSmoke" }]
        },
       
        1731:{
            name: "1731",
            category:"Adult",
            price: "RM 298",
            size:"55-16-145",
            colour:[
            { image: "20231031_213440.jpg", file:'model1731', colour: "Size" },
            {image: "1731_Black.jpg", file:'model1731', colour: "Black"},
            {image: "1731_Blue.jpg", file:'model1731', colour: "Blue"},
            {image: "1731_Brown.jpg", file:'model1731', colour: "Brown"},
            {image: "1731_Green.jpg", file:'model1731', colour: "Green"},
            {image: "1731_Purple.jpg", file:'model1731', colour: "Purple"},
            {image: "1731_Red.jpg", file:'model1731', colour: "Red"},
            {image: "1731_White.jpg", file:'model1731', colour: "WhiteSmoke"}]
        },
        1846:{
            name: "1846",
            category:"Adult",
            price: "RM 298",
            size:"51-17-139",
            colour:[
            { image: "20231031_193651.jpg", file:'model1846', colour: "Size" },
            { image: "1846_Black.jpg", file:'model1846', colour: "Black" },
            { image: "1846_Blue.jpg", file:'model1846', colour: "Blue" },
            { image: "1846_Brown.jpg", file:'model1846', colour: "Brown" },
            { image: "1846_Green.jpg", file:'model1846', colour: "Green" },
            { image: "1846_Purple.jpg", file:'model1846', colour: "Purple" },
            { image: "1846_Red.jpg", file:'model1846', colour: "Red" },
            { image: "1846_White.jpg", file:'model1846', colour: "WhiteSmoke" }]
        },
        1901:{
            name: "1901",
            category:"Adult",
            price: "RM 398",
            size:"54-21-143",
            colour:[
            { image: "20231031_194720.jpg", file:'model1901', colour: "Size" },
            {image: "1901_Black.jpg", file:'model1901', colour: "Black" },
            {image: "1901_Blue.jpg", file:'model1901', colour: "Blue" }]
        },
        1902:{
            name: "1902",
            category:"Adult",
            price: "RM 398",
            size:"54-19-144",
            colour:[
            {image: "20231031_142251.jpg", file:'model1902', colour: "Size" },
            {image: "1902_BlackGold.jpg", file:'model1902', colour: "Black" },
            {image: "1902_BlueSilver.jpg", file:'model1902', colour: "Blue" }]
        },
        19051:{
            name: "1905 Chip",
            category:"Adult",
            price: "RM 498",
            size:"55-17-142",
            colour:[
            { image: "20231031_144815.jpg", file:'model1905chip', colour: "Size" },
            {image: "1905_Chip.jpg", file:'model1905chip', colour: "Black" }]
        },
        6012:{
            name: "6012",
            category:"Children",
            price: "RM 268",
            size:"52-19-142",
            colour:[
            { image: "20231031_142830.jpg", file:'model6012', colour: "Size" },
            { image: "6012_Blue.jpg", file:'model6012', colour: "Blue" },
            { image: "6012_Orange.jpg", file:'model6012', colour: "Orange" },
            { image: "6012_Purple.jpg", file:'model6012', colour: "Purple" },
            { image: "6012_Red.jpg", file:'model6012', colour: "Red" }]
        },
        6601:{
            name: "6601",
            category:"Children",
            price: "RM 298",
            size:"48-16-135",
            colour:[
            { image: "c6ec13cd67ca9700ca19e20ba8d2ec3.jpg", file:'model6601', colour: "Size" },
            {image: "6601_Purple.jpg", file:'model6601', colour: "Purple" }]
        },
        8823:{
            name: "8823",
            category:"Children",
            price: "RM 268",
            size:"52-19-142",
            colour:[
            { image: "20231031_114555.jpg", file:'model8823', colour: "Size" },    
            {image: "8823_Black.jpg", file:'model8823', colour: "Black" },
            {image: "8823_Blue.jpg", file:'model8823', colour: "Blue" },
            {image: "8823_Grey.jpg", file:'model8823', colour: "Grey" },
            {image: "8823_Red.jpg", file:'model8823', colour: "Red" }]
        }
    };

    const testimony = ['hann.jpg', 'ml.jpg', 'nic.jpg', 'peggy.jpg', 'yf.jpg', 'zoey.jpg'];

    const navigate = useNavigate();

    const handleClick = (product) => {
        navigate('/product-details', { state: product_image[product] });
    };

    return (
        <div>
            <img className='image-height' src={require('../../images/Cover_Web.jpg')}/>
            <div className="overlay direct-align-top-right padding-right">
                <Link to="/" hoverable="true">
                    <button className='transparent-button1 first-font font-size-xsmall button-gap1 hover-pointer'> Home </button>
                </Link>
                {/* <Link to="/about">
                    <MyButton initialText="About Us"/>
                </Link> */}
                <Link to="/products">
                    <MyButton initialText="Products"/>
                </Link>
                <Link to="/faq">
                    <MyButton initialText="FAQs"/>
                </Link>
                {/* <Link to="/contact">
                    <MyButton initialText="Contact Us"/>
                </Link> */}
            </div>
            <Row className='padding-top'>
                <Col span={2}></Col>
                <Col span={10} className='border-bottom'>
                    <span className='font-size-xsmall first-font'>PRODUCTS</span>
                </Col>
                <Col span={10} className='text-align-right border-bottom'>
                    <span className='first-font'> <Link to='/products'><MyButton initialText="View More >"/></Link> </span>
                </Col>
                <Col span={2}></Col>
            </Row>
            <Row justify="center">
                <Col span={2}></Col>
                <Col span={20}>
                    <Row justify="center">
                        {Object.keys(product_image).map((index) => (
                            <Col key={index} span={4} className='padding-right padding-top'>
                                <Card cover={<Image src={require(`../../images/${product_image[index].colour[0].file}/${product_image[index].colour[0].image}`)}/>} 
                                actions={[<span onClick={() => handleClick(index)}> View Details</span>]} hoverable>
                                    <Meta
                                        title={product_image[index].name}
                                        description=
                                        {
                                            <Row>
                                                <Col span={24}>
                                                    {product_image[index].size}
                                                </Col>
                                                <Col span={24}>
                                                    {product_image[index].price}
                                                </Col>
                                            </Row>
                                        } 
                                    />
                                </Card>
                            </Col>  
                        ))}
                    </Row>
                </Col>
                <Col span={2}></Col>
            </Row>
            <Row className='padding-top'>
                <Col span={2}></Col>
                <Col span={10} className='border-bottom'>
                    <span className='font-size-xsmall first-font'>TESTIMONY</span>
                </Col>
                <Col span={10} className='text-align-right border-bottom'></Col>
                <Col span={2}></Col>
            </Row>
            <Row justify="center">
                <Col span={2}></Col>
                <Col span={20}>
                    <Row>
                        {testimony.map((testimony, index) => (
                            <Col span={8} key={index}>
                                <Image className='image-size-testimony' src={require(`../../images/testimony/${testimony}`)}/>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={2}></Col>                                 
            </Row>
            <Footer />
        </div>
    );
}

export default Home;