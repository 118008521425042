import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Image  } from 'antd'; 
import './product_details.css';
import Header from '../../component/Header/header';
import Footer from '../../component/Footer/footer';

function ProductDetails() {
    const location = useLocation();
    const product_image = location.state;

    const [colour, setcolour] = useState(0);
    const handleClick = (colour) => {
        setcolour(colour);
    };

    return(
        <div>
            <Header/>
            <Row className='row-padding'>
                <Col span={2}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={9} className='col-border'>
                    <Image  className = 'detail-image-size' src={require(`../../images/${product_image.colour[colour].file}/${product_image.colour[colour].image}`)}/>
                </Col>
                <Col span={2}></Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={0}></Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={9}>
                    <h1 className='font-size-big second-font product-name-margin'>{product_image.name} : {product_image.colour[colour].colour}</h1>
                    <Row className='product-price-margin'>
                        <span className='font-size-medium third-font font-default-color'>{product_image.price}</span>
                    </Row>
                    <Row className='row-top-border product-price-margin'>
                        {product_image.colour.map((product, index) => (
                            <Col xs={3} sm={3} md={3} lg={2} xl={2} className='row-padding-colour'>
                                <button className="circle-btn" style={{ backgroundColor: product.colour }} onClick={() => handleClick(index)}></button>
                            </Col> 
                        ))}
                    </Row>
                    <Row className='product-info-margin' align="middle">
                        <Col span={4}>
                            <span className='font-size-small third-font'>size:</span>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={19}>
                        <span className='font-size-xsmall first-font font-secondary-color'>{product_image.size}</span>
                        </Col>
                    </Row>
                    <Row className='product-info-margin' align="middle">
                        <Col span={4}>
                            <span className='font-size-small third-font'>category:</span>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={19}>
                            <span className='font-size-xsmall first-font font-secondary-color'>{product_image.category}</span>
                        </Col>
                    </Row>
                    <Row className='product-info-margin' align="top">
                        <Col span={4}>
                            <p className='font-size-small third-font no-marigin-top product-info-margin'>benefit:</p>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={19} className='product-info-margin-top'>
                            <p className='no-marigin-top product-info-margin font-size-xsmall first-font font-secondary-color'>Negative Ions, Anti-Blue Light, </p>
                            <p className='no-marigin-top product-info-margin font-size-xsmall first-font font-secondary-color'>Far-Infrared Rays, Anti-EMF Radiation</p>
                            <p className='no-marigin-top product-info-margin font-size-xsmall first-font font-secondary-color'>UV Protection</p>
                        </Col>
                    </Row>
                </Col>
                <Col span={2}></Col>
            </Row>
            <Footer />
        </div>
    )
}

export default ProductDetails;