import React from 'react';
import './faq.css';
import Header from '../../component/Header/header';
import Footer from '../../component/Footer/footer';
import { Row, Col, Collapse} from 'antd';

function FAQ() {
    const items = [
        {
            key: '1',
            label: 'Who is recommended to use OxySpec?',
            children: <p>In the 5G era, eyes glued to screens has become a daily norm! OXYSPEC is an essential eye protector for everyone.</p>,
        },
        {
            key: '2',
            label: 'What are the differences between OxySpec and regular spectacles?',
            children: <p>
            OXYSPEC is an upgraded version of functional eyewear, not only correcting refractive issues but also improving and preventing eye diseases.</p>,
        },
        {
            key: '3',
            label: 'What are the instructions for using OxySpec?',
            children: <p>Simply wear it; no special operations are required. It's simple, effective, safe, and has no side effects.</p>,
        },
        {
            key: '4',
            label: 'When and how should OxySpec be worn?',
            children: <p>
            Health is a lifelong lesson. Advocate for "open your eyes, wear glasses," 8 hours a day, keep the doctor away.</p>,
        },
        {
            key: '5',
            label: 'What is the recommended method for cleaning OxySpec?',
            children: <p>
            Regularly rinse with clean water, wipe dry with an eyeglass cloth. For outdoor workers or those who sweat heavily, you can use hand soap to remove oil and dirt.</p>,
        },
        {
            key: '6',
            label: 'Does OxySpec offer eyeglasses with a prescription option?',
            children: <p>The lenses are 0 diopters, suitable for friends without refractive issues. However, friends with refractive problems must arrange for their own prescription lenses.</p>,
        },
        {
            key: '7',
            label: 'How would you experience wearing OxySpec?',
            children: <p>
            "Comfortable, eyes not dry or tired, more energetic" are common feedback from customers. However, individuals with potential health issues may experience slight dizziness, tearing, or eye discharge.</p>,
        },
        {
            key: '8',
            label: 'Does OXYSPEC have an expiration date for its efficacy?',
            children: <p>OXYSPEC is developed using nano-technology, providing a permanently effective safeguard for your eyes.</p>,
        },
    ];

    // const onChange = (key) => {
    // };
    
    return (
        <div>
            <Header/>
            <Row className='faq-background-image' justify="center" align="middle">
                <Col>
                    <h1 className='font-size-big third-font'>Frequently Asked Questions ?</h1>
                </Col>
            </Row>
            <Collapse items={items} defaultActiveKey={['1']} />
            <Footer />
        </div>
    )
}

export default FAQ;
