import './product.css';
import Header from '../../component/Header/header';
import Footer from '../../component/Footer/footer';
import { Row, Col, Card, Image } from 'antd'; 
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useNavigate  } from 'react-router-dom';

const { Meta } = Card;

function Product() {
    const product_image =
        {
            1705:{
                name: "1705",
                category:"Adult",
                price: "RM 268",
                size:"50-18-137",
                colour:[
                { image: "1705_Black.jpg", file:'1705', colour: "Black" },
                { image: "1705_Blue.jpg", file:'1705', colour: "Blue" },
                { image: "1705_Brown.jpg", file:'1705', colour: "Brown" },
                { image: "1705_Green.jpg", file:'1705', colour: "Green" },
                { image: "1705_Purple.jpg", file:'1705', colour: "Purple" },
                { image: "1705_Red.jpg", file:'1705', colour: "Red" },
                { image: "1705_White.jpg", file:'1705', colour: "WhiteSmoke" }]
            },
            1708:{
                name: "1708",
                category:"Adult",
                price: "RM 268",
                size:"50-18-137",
                colour:[
                {image: "1708_Black.jpg", file:'1708',colour: "Black"},
                {image: "1708_Blue.jpg", file:'1708', colour: "Blue" },
                {image: "1708_Brown.jpg", file:'1708', colour: "Brown"},
                {image: "1708_Purple.jpg", file:'1708', colour: "Purple"},
                {image: "1708_Red.jpg", file:'1708', colour: "Red"}]
            },  
            1709:{
                name: "1709",
                category:"Adult",
                price: "RM 268",
                size:"55-16-142",
                colour:[
                {image: "1709_Black.jpg", file:'model1709',colour: "Black"},
                {image: "1709_Blue.jpg", file:'model1709', colour: "Blue"},
                {image: "1709_Purple.jpg", file:'model1709', colour: "Purple"},
                {image: "1709_Red.jpg", file:'model1709', colour: "Red"}]
            }, 
            1731:{
                name: "1731",
                category:"Adult",
                price: "RM 298",
                size:"55-16-145",
                colour:[
                {image: "1731_Black.jpg", file:'model1731', colour: "Black"},
                {image: "1731_Blue.jpg", file:'model1731', colour: "Blue"},
                {image: "1731_Brown.jpg", file:'model1731', colour: "Brown"},
                {image: "1731_Green.jpg", file:'model1731', colour: "Green"},
                {image: "1731_Purple.jpg", file:'model1731', colour: "Purple"},
                {image: "1731_Red.jpg", file:'model1731', colour: "Red"},
                {image: "1731_White.jpg", file:'model1731', colour: "WhiteSmoke"}]
            },
            1826:{
                name: "1826",
                category:"Adult",
                price: "RM 298",
                size:"55-16-142",
                colour:[
                {image: "1826_Black.jpg", file:'model1826', colour: "Black"},
                {image: "1826_Blue.jpg", file:'model1826', colour: "Blue"},
                {image: "1826_Green.jpg", file:'model1826', colour: "Green"},
                {image: "1826_Purple.jpg", file:'model1826', colour: "Purple"},
                {image: "1826_Red.jpg", file:'model1826', colour: "Red"},
                {image: "1826_White.jpg", file:'model1826', colour: "WhiteSmoke"}]
            },
            1846:{
                name: "1846",
                category:"Adult",
                price: "RM 298",
                size:"51-17-139",
                colour:[
                { image: "1846_Black.jpg", file:'model1846', colour: "Black" },
                { image: "1846_Blue.jpg", file:'model1846', colour: "Blue" },
                { image: "1846_Brown.jpg", file:'model1846', colour: "Brown" },
                { image: "1846_Green.jpg", file:'model1846', colour: "Green" },
                { image: "1846_Purple.jpg", file:'model1846', colour: "Purple" },
                { image: "1846_Red.jpg", file:'model1846', colour: "Red" },
                { image: "1846_White.jpg", file:'model1846', colour: "WhiteSmoke" }]
            },
            1890:{
                name: "1890",
                category:"Adult",
                price: "RM 298",
                size:"55-16-147",
                colour:[
                {image: "1890_Black.jpg", file:'model1890', colour: "Black" },
                {image: "1890_Blue.jpg", file:'model1890', colour: "Blue" },
                {image: "1890_Purple.jpg", file:'model1890', colour: "Purple" },
                {image: "1890_Red.jpg", file:'model1890', colour: "Red" }]
            },
            1901:{
                name: "1901",
                category:"Adult",
                price: "RM 398",
                size:"54-21-143",
                colour:[
                {image: "1901_Black.jpg", file:'model1901', colour: "Black" },
                {image: "1901_Blue.jpg", file:'model1901', colour: "Blue" }]
            },
            1902:{
                name: "1902",
                category:"Adult",
                price: "RM 398",
                size:"54-19-144",
                colour:[
                {image: "1902_BlackGold.jpg", file:'model1902', colour: "Black" },
                {image: "1902_BlueSilver.jpg", file:'model1902', colour: "Blue" }]
            },
            1904:{
                name: "1904",
                category:"Adult",
                price: "RM 298",
                size:"55-16-147",
                colour:[
                {image: "1904_Black.jpg", file:'model1904', colour: "Black" },
                {image: "1904_Green.jpg", file:'model1904', colour: "Green" },
                {image: "1904_Purple.jpg", file:'model1904', colour: "Purple" }]
            },
            1905:{
                name: "1905",
                category:"Adult",
                price: "RM 298",
                size:"54-21-143",
                colour:[
                {image: "1905_Black.jpg", file:'model1905', colour: "Black" },
                {image: "1905_Blue.jpg", file:'model1905', colour: "Blue" },
                {image: "1905_Purple.jpg", file:'model1905', colour: "Purple" },
                {image: "1905_Red.jpg", file:'model1905', colour: "Red" }]
            },
            1912:{
                name: "1912",
                category:"Adult",
                price: "RM 298",
                size:"57-16-140",
                colour:[
                {image: "1912_Black.jpg", file:'model1912', colour: "Black" },
                {image: "1912_Blue.jpg", file:'model1912', colour: "Blue" },
                {image: "1912_Green.jpg", file:'model1912', colour: "Green" },
                {image: "1912_Purple.jpg", file:'model1912', colour: "Purple" },
                {image: "1912_Red.jpg", file:'model1912', colour: "Red" },
                {image: "1912_White.jpg", file:'model1912', colour: "WhiteSmoke" }]
            },
            1913:{
                name: "1913",
                category:"Adult",
                price: "RM 298",
                size:"52-17-140",
                colour:[
                {image: "1913_Black.jpg", file:'model1913', colour: "Black" },
                {image: "1913_Blue.jpg", file:'model1913', colour: "Blue" },
                {image: "1913_Brown.jpg", file:'model1913', colour: "Brown" },
                {image: "1913_Green.jpg", file:'model1913', colour: "Green" },
                {image: "1913_Purple.jpg", file:'model1913', colour: "Purple" },
                {image: "1913_Red.jpg", file:'model1913', colour: "Red" },
                {image: "1913_White.jpg", file:'model1913', colour: "WhiteSmoke" }]
            },
            2059:{
                name: "2059",
                category:"Adult",
                price: "RM 398",
                size:"49-18-140",
                colour:[
                {image: "2059_Black.jpg", file:'model2059', colour: "Black" },
                {image: "2059_Purple.jpg", file:'model2059', colour: "Purple" },
                {image: "2059_Red.jpg", file:'model2059', colour: "Red" }]
            },
            2103:{
                name: "2103",
                category:"Adult",
                price: "RM 398",
                size:"52-17-142",
                colour:[
                {image: "2103_Black.jpg", file:'model2103', colour: "Black" },
                {image: "2103_Blue.jpg", file:'model2103', colour: "Blue" },
                {image: "2103_Grey.jpg", file:'model2103', colour: "Grey" },
                {image: "2103_Red.jpg", file:'model2103', colour: "Red" }]
            },
            2121:{
                name: "2121",
                category:"Adult",
                price: "RM 298",
                size:"51-20-145",
                colour:[
                {image: "2121_Black.jpg", file:'model2121', colour: "Black" },
                {image: "2121_Blue.jpg", file:'model2121', colour: "Blue" },
                {image: "2121_Grey.jpg", file:'model2121', colour: "Grey" },
                {image: "2121_Red.jpg", file:'model2121', colour: "Red" }]
            },
            2126:{
                name: "2126",
                category:"Adult",
                price: "RM 398",
                size:"56-18-142",
                colour:[
                {image: "2126_Black.jpg", file:'model2126', colour: "Black" },
                {image: "2126_Blue.jpg", file:'model2126', colour: "Blue" },
                {image: "2126_Brown.jpg", file:'model2126', colour: "Brown" }]
            },
            2809:{
                name: "2809",
                category:"Adult",
                price: "RM 298",
                size:"52-19-142",
                colour:[
                {image: "2809_Black.jpg", file:'model2809', colour: "Black" },
                {image: "2809_Green.jpg", file:'model2809', colour: "Green" },
                {image: "2809_Purple.jpg", file:'model2809', colour: "Purple" },
                {image: "2809_Red.jpg", file:'model2809', colour: "Red" }]
            },
            6012:{
                name: "6012",
                category:"Children",
                price: "RM 268",
                size:"52-19-142",
                colour:[
                { name: "6012: Blue", size:"46-15-125", image: "6012_Blue.jpg", file:'model6012', colour: "Blue" },
                { name: "6012: Orange", size:"46-15-125", image: "6012_Orange.jpg", file:'model6012', colour: "Orange" },
                { name: "6012: Purple", size:"46-15-125", image: "6012_Purple.jpg", file:'model6012', colour: "Purple" },
                { name: "6012: Red", size:"46-15-125", image: "6012_Red.jpg", file:'model6012', colour: "Red" }]
            },
            6601:{
                name: "6601",
                category:"Children",
                price: "RM 298",
                size:"48-16-135",
                colour:[
                {image: "6601_Purple.jpg", file:'model6601', colour: "Purple" }]
            },
            8823:{
                name: "8823",
                category:"Children",
                price: "RM 268",
                size:"52-19-142",
                colour:[
                {image: "8823_Black.jpg", file:'model8823', colour: "Black" },
                {image: "8823_Blue.jpg", file:'model8823', colour: "Blue" },
                {image: "8823_Grey.jpg", file:'model8823', colour: "Grey" },
                {image: "8823_Red.jpg", file:'model8823', colour: "Red" }]
            },
            92:{
                name: "f92",
                category:"Adult",
                price: "RM 298",
                size:"48-16-135",
                colour:[
                {image: "f92_Black.jpg", file:'modelf92', colour: "Black" }]
            },
            19051:{
                name: "1905 Chip",
                category:"Adult",
                price: "RM 498",
                size:"55-17-142",
                colour:[
                {image: "1905_Chip.jpg", file:'model1905chip', colour: "Black" }]
            },
            19071:{
                name: "1907 Chip",
                category:"Adult",
                price: "RM 498",
                size:"58-18-141",
                colour:[
                {image: "1907_Chip.jpg", file:'model1907chip', colour: "Black" }]
            }
        };

    const [product__details_id, setProduct_Details_id] = useState(0);
    const [product_id, setProduct_id] = useState();
    const handleClick = (key, product) => {
        setProduct_Details_id(product);
        setProduct_id(key);
    };

    const navigate = useNavigate();

    const handlegotoProductDetails = (product) => {
        navigate('/product-details', { state: product_image[product] });
    };

    return (
        <div>
            <Header/>
            <Row>
                <Col>
                    <img className='image-height' src={require('../../images/product_main.jpg')}/>
                </Col>
            </Row>
            <Row>
                <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                    <Row justify="center">
                        {Object.keys(product_image).map((productKey) => (
                            <Col xs={11} sm={11} md={11} lg={6} xl={4} key={productKey} className='padding-right padding-top'>
                                 <Card cover=
                                        {
                                            <div>
                                                {product_id == productKey ? (
                                                    <Image src={require(`../../images/${product_image[product_id].colour[product__details_id].file}/${product_image[product_id].colour[product__details_id].image}`)}/> 
                                                ) : (
                                                    <Image src={require(`../../images/${product_image[productKey].colour[0].file}/${product_image[productKey].colour[0].image}`)}/> 
                                                )}    
                                            </div>
                                           
                                        } actions={[<span onClick={() => handlegotoProductDetails(productKey)}> View Details</span>]}>
                                    <Row>
                                        <Meta title={product_image[productKey].name}  
                                            description={
                                                <Row>
                                                    <Col span={24}>
                                                        {product_image[productKey].size}
                                                    </Col>
                                                    <Col span={24}>
                                                        {product_image[productKey].price}
                                                    </Col>
                                                </Row>
                                            } />
                                    </Row>
                                    <Row className='padding-top'>
                                        {product_image[productKey].colour.map((product, index) => (
                                            <Col xs={3} sm={3} md={3} lg={3} xl={2} key={product.image}>
                                                <Meta description={ 
                                                    <button className="circle-btn-product" style={{ backgroundColor: product.colour }} onClick={() => handleClick(productKey, index)}></button>
                                                }/>
                                            </Col>
                                        ))}
                                    </Row>
                                 </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
            </Row>
            <Footer />
        </div>
    )
}

export default Product;