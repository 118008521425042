import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/home';
import MobileHome from './pages/Mobile/mobile_home';
import Product from './pages/Product/product';
import { useMediaQuery } from 'react-responsive'
import ProductDetails from './pages/ProductDetails/product_details';
import FAQ from './pages/FAQ/faq';

function App() {
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });
  
  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1025px)",
  });
  
  
  return (
    <Router>
      <Routes>
        {isMobileDevice && (
          <Route path="/" element={<MobileHome />} />
        )}
         {isLaptop && (
          <Route path="/" element={<Home />} />
        )}
        <Route path="/products" element={<Product/>}/>
        <Route path="/product-details" element={<ProductDetails/>}/>
        <Route path="/faq" element={<FAQ/>}/>
      </Routes>
    </Router>
  );
}

export default App;
