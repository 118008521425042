import React from 'react';
import './footer.css';
import { Row, Col} from 'antd';

function Footer() {
    return (
        <footer>
            <Row className='footer-background footer-height'>
                <Col span={2}></Col>
                <Col>
                    <Row>
                        <h1 className='font-size-xsmall first-font font-color'>Contact Us</h1>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <span className='font-size-xsmall third-font font-color'>WhatsApp: </span>
                        </Col>
                        <Col span={18}>
                            <span className='font-size-xsmall second-font font-color'> 013-278 5699</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <span className='font-size-xsmall third-font font-color'>Facebook: </span>
                        </Col>
                        <Col span={18}>
                            <a className='font-size-xsmall second-font font-color' href='https://www.facebook.com/profile.php?id=61552498902258'> OxyySpec Facebook</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <span className='font-size-xsmall third-font font-color'>Address: </span>
                        </Col>
                        <Col span={18}>
                            <Row>
                                <span className='font-size-xsmall second-font font-color'> No. 7, Jalan Alfa Impian 1,</span>
                            </Row>
                            <Row>
                                <span className='font-size-xsmall second-font font-color'> Taman Perindustrian Alfa Impian, Seri Kembangan</span>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={2}></Col>
            </Row>
        </footer>
    )
}

export default Footer;