import React from 'react';
import { Button, Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';
import MyButton from '../Button/button';
import './menu.css';
import {
    MenuOutlined
  } from '@ant-design/icons';

function Menu() {
    const items = [
        {
          key: '1',
          label: (
            <Link to="/">
                <MyButton initialText="Home"/>
            </Link>
          ),
        },
        {
          key: '2',
          label: (
            <Link to="/products">
                <MyButton initialText="Products"/>
            </Link>
          ),
        },
        {
          key: '3',
          label: (
            <Link to="/faq">
                <MyButton initialText="FAQs"/>
            </Link>
          ),
        },
        // {
        //   key: '4',
        //   label: (
        //     <Link to="/contact">
        //         <MyButton initialText="Contact Us"/>
        //     </Link>
        //   ),
        // },
    ];

    return (
        <Space direction="vertical">
             <Space wrap>
                <Dropdown
                    menu={{
                    items,
                    }}
                    placement="bottomRight"
                >
                    <MenuOutlined className='font-color menu-icon-size'/>
                </Dropdown>
             </Space>
        </Space>
    )
}

export default Menu;