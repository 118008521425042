import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';
import MyButton from '../Button/button';

function Header() {
    return (
        <header>
            <div className='box-colour header-padding'>
                <div className='direct-allign-bottom-center'>
                    <img className='image-size' src={require('../../images/logo.jpg')}/>
                </div>
                <div className='direct-allign-bottom-center header-button-height'>
                    <Link to="/">
                        <MyButton id="mobile-menu-button" initialText="Home"/>
                    </Link>
                    {/* <Link to="/about">
                        <MyButton initialText="About Us"/>
                    </Link> */}
                    <Link to="/products">
                        <MyButton initialText="Products"/>
                    </Link>
                    <Link to="/faq">
                        <MyButton initialText="FAQs"/>
                    </Link>
                    {/* <Link to="/contact">
                        <MyButton initialText="Contact Us"/>
                    </Link> */}
                </div>
            </div>
        </header>
    )
}

export default Header;